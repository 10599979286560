/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.slim

import "core-js/stable";
import "regenerator-runtime/runtime";
import ModalControl from './modal-control';
import AppVue from './app-vue'; // MUST HAVE, don't remove!

document.addEventListener('DOMContentLoaded', function (e) {

  /**
   * HANDLE LOGIN
   *
   * @type {HTMLElement}
   */
  var loginLink = document.getElementById('login');
  if (loginLink) {
    loginLink.addEventListener('click', function (e) {

      ModalControl.show(
        'modal-login',
        {
          no_button: true,
          modal_classes: ['no-seperator', 'pb-0'],
          content_classes: [],
          dialog_classes: ['w-380'],
          header_classes: ['no-seperator', 'pb-0'],
          footer_classes: ['no-seperator', 'mx-3', 'flex-wrap']
        }
      );
    });
    if (window.location.hash.includes('login=true')) {
      loginLink.click();
    }
  }


  /**
   * HANDLE ModalSlider.
   */
  function modalSlider() {

    var miniCarousel = document.getElementById('mini-carousel');
    if (!miniCarousel) {
      return;
    }

    miniCarousel.addEventListener('click', function (e) {
      if ((e.target.tagName).toLowerCase() === 'img') {

        ModalControl.show(
          'modal-slider',
          {
            no_button: true,
            dialog_classes: ['no-background', 'w-65p'],
            content_classes: ['no-background'],
            header_classes: ['no-seperator'],
            footer_classes: ['no-seperator'],
            active_slide: e.target.parentElement.getAttribute('data-slide-num')
          }
        );
      }
    });

  }

  modalSlider();


  /**
   * HANDLE Offer Backend-Actions.
   */
  function handleOfferBackendActions() {

    let activateConfirmed = function (close) {
      let request = $.get(actionParams.url, {op: 'activate', offer: actionParams.offer});
      request.done((result) => {
        if (result.state === 'success') {
          window.location.reload(true);
        } else {
          console.error('Activation of offer' + actionParams.offer + 'failed: ', result.msg);
          showErrMsg();
        }
      });
      close();
    };

    let deactivateConfirmed = function (close) {
      let request = $.get(actionParams.url, {op: 'deactivate', offer: actionParams.offer});
      request.done((result) => {
        if (result.state === 'success') {
          window.location.reload(true);
        } else {
          console.error('Deactivation of offer' + actionParams.offer + 'failed: ', result.msg);
          showErrMsg();
        }
      });
      close();
    };

    let setTeaserSelected = function (close, ref) {
      let request = $.get(actionParams.url, {
        op: 'set_teaser',
        offer: actionParams.offer,
        f_id: ref.$parent._data.selected
      });
      request.done((result) => {
        if (result.state === 'success') {
          window.location.reload(true);
        } else {
          console.error('Set teaser of offer' + actionParams.offer + 'failed: ', result.msg);
          showErrMsg();
        }
      });
      close();
    };

    let unsetTeaserSelected = function (close, ref) {
      let request = $.get(actionParams.url, {
        op: 'unset_teaser',
        offer: actionParams.offer,
        f_id: ref.$parent._data.selected
      });
      request.done((result) => {
        if (result.state === 'success') {
          window.location.reload(true);
        } else {
          console.error('Unset teaser of offer' + actionParams.offer + 'failed: ', result.msg);
          showErrMsg();
        }
      });
      close();
    };

    let setSaleConfirmed = function (close) {
      let request = $.get(actionParams.url, {op: 'set_sale', offer: actionParams.offer});
      request.done((result) => {
        if (result.state === 'success') {
          window.location.reload(true);
        } else {
          console.error('Set SALE of offer' + actionParams.offer + 'failed: ', result.msg);
          showErrMsg();
        }
      });
      close();
    };

    let unsetSaleConfirmed = function (close) {
      let request = $.get(actionParams.url, {op: 'unset_sale', offer: actionParams.offer});
      request.done((result) => {
        if (result.state === 'success') {
          window.location.reload(true);
        } else {
          console.error('Unset SALE of offer' + actionParams.offer + 'failed: ', result.msg);
          showErrMsg();
        }
      });
      close();
    };

    let setSpecialConfirmed = function (close) {
      let request = $.get(actionParams.url, {op: 'set_special', offer: actionParams.offer});
      request.done((result) => {
        if (result.state === 'success') {
          window.location.reload(true);
        } else {
          console.error('Set SALE of offer' + actionParams.offer + 'failed: ', result.msg);
          showErrMsg();
        }
      });
      close();
    };

    let unsetSpecialConfirmed = function (close) {
      let request = $.get(actionParams.url, {op: 'unset_special', offer: actionParams.offer});
      request.done((result) => {
        if (result.state === 'success') {
          window.location.reload(true);
        } else {
          console.error('Unset SALE of offer' + actionParams.offer + 'failed: ', result.msg);
          showErrMsg();
        }
      });
      close();
    };

    // define backend actions
    let offerBackendActions = [
      {
        btnClass: 'offer-activate-btn',
        actionConfirmedCallback: activateConfirmed,
        question: 'Angebot "<strong>###</strong>",<br> <mark><strong>aktiv</strong></mark> schalten, sind Sie sicher?'
      },
      {
        btnClass: 'offer-deactivate-btn',
        actionConfirmedCallback: deactivateConfirmed,
        question: 'Angebot "<strong>###</strong>",<br><mark><strong>inaktiv</strong></mark> schalten, sind Sie sicher?'
      },
      {
        btnClass: 'offer-set-teaser-btn',
        actionConfirmedCallback: setTeaserSelected,
        question: 'Angebot "<strong>###</strong>",<br>einer Kategorie als Startseitenteaser <mark><strong>hinzufügen</strong></mark>, sind Sie sicher?',
        modal: 'modal-simple-select'
      },
      {
        btnClass: 'offer-unset-teaser-btn',
        actionConfirmedCallback: unsetTeaserSelected,
        question: 'Angebot "<strong>###</strong>",<br>von Startseitenteasern <mark><strong>entfernen</strong></mark>, sind Sie sicher? <br><br><em><i class="fas fa-exclamation-triangle"></i> Belassen Sie die Kategorie auf der aktuellen Auswahl und bestätigen, wird das Angebot von den Teasern entfernt, verändern Sie die Kategorie, wird die neue Zuordnung gespeichert.</em>',
        modal: 'modal-simple-select'
      },
      {
        btnClass: 'offer-set-sale-btn',
        actionConfirmedCallback: setSaleConfirmed,
        question: 'Angebot "<strong>###</strong>",<br>als <mark><strong>SALE Aktion</strong></mark> markieren, sind Sie sicher?'
      },
      {
        btnClass: 'offer-unset-sale-btn',
        actionConfirmedCallback: unsetSaleConfirmed,
        question: 'Angebot "<strong>###</strong>",<br>von <mark><strong>SALE Aktion</strong></mark> entfernen, sind Sie sicher?'
      },
      {
        btnClass: 'offer-set-special-btn',
        actionConfirmedCallback: setSpecialConfirmed,
        question: 'Angebot "<strong>###</strong>",<br>als <mark><strong>SONDERAKTION</strong></mark> markieren, sind Sie sicher?'
      },
      {
        btnClass: 'offer-unset-special-btn',
        actionConfirmedCallback: unsetSpecialConfirmed,
        question: 'Dem Angebot die "<strong>###</strong>",<br><mark><strong>SONDERAKTION</strong></mark> Markierung entziehen, sind Sie sicher?'
      }
    ];

    let actionBtns      = null,
        actionBtn       = null,
        actionParams    = null,
        actionBtnsIndex = 0;

    offerBackendActions.forEach(function (oBEAction) {
      actionBtns = document.getElementsByClassName(oBEAction.btnClass);
      for (actionBtnsIndex = 0; actionBtnsIndex < actionBtns.length; actionBtnsIndex++) {
        actionBtn = actionBtns[actionBtnsIndex];
        actionBtn.addEventListener('click', function (e) {

          actionParams = {offer: this.dataset.offerId, url: this.dataset.url};

          if (!oBEAction.hasOwnProperty('modal')) {
            ModalControl.show(
              'modal-simple-confirm',
              {
                title: 'Angebot #' + this.dataset.offerId + ' verändern',
                question: oBEAction.question.replace('###', this.dataset.offerTitle),
                buttons: [
                  ModalControl.BUTTON_CLOSE,
                  {classes: 'btn btn-primary', action: oBEAction.actionConfirmedCallback, text: 'Bestätigen'}
                ]
              }
            );
          } else {
            ModalControl.show(
              oBEAction.modal,
              {
                title: 'Angebot #' + this.dataset.offerId + ' verändern',
                question: oBEAction.question.replace('###', this.dataset.offerTitle),
                buttons: [
                  ModalControl.BUTTON_CLOSE,
                  {classes: 'btn btn-primary', action: oBEAction.actionConfirmedCallback, text: 'Bestätigen'}
                ],
                select_title: 'Bitte wählen sie die Kategorie:',
                preselect: Number(this.dataset.teaser),
                options: this.dataset.options
              }
            );
          }

        });
      }
    });

    /**
     * Show given error message in VUE modal.
     *
     * @param msg
     */
    function showErrMsg(msg = null) {

      if (msg === null) {
        msg = 'Ein unerwarteter Fehler ist aufgetreten.';
      }

      ModalControl.show(
        'modal-message',
        {
          message: msg
        }
      );
    }
  }

  handleOfferBackendActions();

  /**
   * Handle edit/remove seo_text
   */
  function handleSeoTextActions() {
    var removeSeoTextBtns = document.getElementsByClassName('remove-seo-text-btn');
    if (!removeSeoTextBtns || removeSeoTextBtns.length <= 0) {
      return;
    }

    for (let removeBtn of removeSeoTextBtns) {
      removeBtn.addEventListener('click', function (e) {
        let btn = e.target;
        if (btn.classList.contains('fas')) {
          btn = btn.parentNode;
        }

        ModalControl.show(
          'modal-simple-confirm',
          {
            title: 'SEO Text #' + this.dataset.id + ' löschen',
            question: 'Sind Sie sicher?',
            buttons: [
              {
                classes: 'btn btn-secondary',
                action: function (close) {
                  close();
                },
                text: 'Abbrechen'
              },
              {
                classes: 'btn btn-primary',
                action: function (close, element) {
                  let request = $.get(btn.dataset.url, {id: btn.dataset.id});
                  request.done((result) => {
                    if (result.state === 'failed') {
                      console.error('ERROR MESSAGE: ', result.msg);
                      element.showAlertMessage(result.msg);
                    } else {
                      window.location.reload(true);
                    }
                  });
                  // close();
                },
                text: 'Bestätigen'
              }
            ]
          }
        );


      });
    }
  }
  handleSeoTextActions();

  /**
   * Handle employee actions of dealers.
   */
  function handleDealerEmployeeActions() {

    let resendInvitationBtns = document.getElementsByClassName('resend-invitation-btn'),
        chancelEmployeeBtns = document.getElementsByClassName('chancel-employee-btn'),
        sendInvitationBtn = document.getElementById('send-invitation-btn');

    if ( (!resendInvitationBtns || resendInvitationBtns.length <= 0) &&
         (!chancelEmployeeBtns || chancelEmployeeBtns.length <= 0) &&
         !sendInvitationBtn )
    {
      return;
    }

    if ( resendInvitationBtns && resendInvitationBtns.length > 0 ) {
      for (let resendInvitationBtn of resendInvitationBtns) {
        resendInvitationBtn.addEventListener('click', function (e) {
          let btn = e.target;
          if (btn.classList.contains('fas')) {
            btn = btn.parentNode;
          }

          let request = $.get(btn.dataset.url);
          request.done((result) => {
            if (result.state === 'failed') {
              console.error('ERROR MESSAGE: ', result.msg);

              ModalControl.show(
                'modal-message',
                {
                  message: result.msg
                }
              );

            } else {
              // window.location.reload(true);

              ModalControl.show(
                'modal-message',
                {
                  message: 'Einladung wurde erneut versandt!',
                  message_classes: 'btn-success'
                }
              );

            }
          });
        });
      }
    }

    if ( chancelEmployeeBtns && chancelEmployeeBtns.length > 0 ) {
      for (let chancelEmployeeBtn of chancelEmployeeBtns) {
        chancelEmployeeBtn.addEventListener('click', function (e) {
          let btn = e.target;
          if (btn.classList.contains('fas')) {
            btn = btn.parentNode;
          }

          ModalControl.show(
            'modal-simple-confirm',
            {
              title: 'Mitarbeiter oder Einladung #' + this.dataset.id + ' entlassen/aufheben',
              question: 'Sind Sie sicher?',
              buttons: [
                {
                  classes: 'btn btn-secondary',
                  action: function (close) {
                    close();
                  },
                  text: 'Abbrechen'
                },
                {
                  classes: 'btn btn-primary',
                  action: function (close, element) {
                    element.showProgressThrobber();

                    let request = $.get(btn.dataset.url);
                    request.done((result) => {
                      if (result.state === 'failed') {
                        console.error('ERROR MESSAGE: ', result.msg);
                        element.showAlertMessage(result.msg);
                      } else {
                        close();
                        window.location.reload();
                      }
                    });
                    // close();
                  },
                  text: 'Bestätigen'
                }
              ]
            }
          );

        });
      }
    }

    sendInvitationBtn.addEventListener('click', function (e) {
      let invitationMail = document.getElementById('invitation_email');

      // ignore empty invitation e-mail
      if ( !invitationMail.value || invitationMail.value === '') {
        return;
      }

      let request = $.post(e.target.dataset.url, {op: 'invite', id: e.target.dataset.id, invitation_email: invitationMail.value});

      // disabled button & show loading state
      document.getElementById('send-invitation-spinner').classList.remove('d-none');
      e.target.disabled = 'disabled';
      e.target.classList.add('disabled');

      request.done((result) => {
        if (result.state === 'failed') {
          console.error('ERROR MESSAGE: ', result.msg);
          ModalControl.show(
            'modal-message',
            {
              message: result.msg
            }
          );
        } else {
          invitationMail.value = '';
          window.location.reload();
        }
      });
    });
  }
  handleDealerEmployeeActions();



  /**
   * Handle add/remove from Sync Action of mobile.de CarImports
   */
  function handleMobileDeImportActions() {
    let addToSnycBtns = document.getElementsByClassName('add-to-sync-btn'),
        removeFromSnycBtns = document.getElementsByClassName('remove-from-sync-btn');

    if (addToSnycBtns && addToSnycBtns.length > 0) {
      for (let addToSnycBtn of addToSnycBtns) {
        addToSnycBtn.addEventListener('click', function (e) {
          syncToggleClickHandler(
            e,
            'Fahrzeug Import #' + this.dataset.id + ' zu Synchronisation hinzufügen',
            '<strong>ACHTUNG!</strong> Bei aktivem Sync werden ggf. vorgenommene Änderungen an Fahrzeugdaten auf GetYourDrive überschrieben und gehen verloren! <br><br>Synchronisation aktivieren, sind Sie sicher?'
          );
        });
      }
    }
    if (removeFromSnycBtns && removeFromSnycBtns.length > 0) {
      for (let removeFromSnycBtn of removeFromSnycBtns) {
        removeFromSnycBtn.addEventListener('click', function (e) {
          syncToggleClickHandler( e, 'Fahrzeug Import #' + this.dataset.id + ' von Synchronisation ausnehmen' );
        });
      }
    }

    function syncToggleClickHandler( e, title, customQuestion = null ) {
      let btn = e.target;
      if (btn.classList.contains('fas')) {
        btn = btn.parentNode;
      }

      ModalControl.show(
        'modal-simple-confirm',
        {
          title: title,
          question: (customQuestion !== null && customQuestion !== '') ? customQuestion : 'Sind Sie sicher?',
          buttons: [
            {
              classes: 'btn btn-secondary',
              action: function(close){ close(); },
              text: 'Abbrechen'
            },
            {
              classes: 'btn btn-primary',
              action: function( close, element ){
                let request = $.get(btn.dataset.url, {id: btn.dataset.id});
                request.done((result) => {
                  if (result.state === 'failed') {
                    console.error('ERROR MESSAGE: ', result.msg);
                    element.showAlertMessage(result.msg );
                  }
                  else {
                    window.location.reload(true);
                  }
                });
                close();
              },
              text: 'Bestätigen'
            }
          ]
        }
      );
    }
  }
  handleMobileDeImportActions();

  /**
   * Handle add/remove from Sync Action of mobile CSV CarImports
   */
  function handleMobileCsvImportActions() {
    let addCarToSyncBtns = document.getElementsByClassName('add-car-to-sync-btn'),
        addOfferToSyncBtns = document.getElementsByClassName('add-offer-to-sync-btn'),
        removeCarFromSyncBtns = document.getElementsByClassName('remove-car-from-sync-btn'),
        removeOfferFromSyncBtns = document.getElementsByClassName('remove-offer-from-sync-btn');

    if (addCarToSyncBtns && addCarToSyncBtns.length > 0) {
      for (let addToSnycBtn of addCarToSyncBtns) {
        addToSnycBtn.addEventListener('click', function (e) {
          syncToggleClickHandler(
            e,
            'Fahrzeug Import #' + this.dataset.id + ' zu Synchronisation hinzufügen',
            '<strong>ACHTUNG!</strong> Bei aktivem Sync werden ggf. vorgenommene Änderungen an Fahrzeugdaten auf GetYourDrive überschrieben und gehen verloren! <br><br>Synchronisation aktivieren, sind Sie sicher?'
          );
        });
      }
    }
    if (addOfferToSyncBtns && addOfferToSyncBtns.length > 0) {
      for (let addToSnycBtn of addOfferToSyncBtns) {
        addToSnycBtn.addEventListener('click', function (e) {
          syncToggleClickHandler(
            e,
            'Angebots Import #' + this.dataset.id + ' zu Synchronisation hinzufügen',
            '<strong>ACHTUNG!</strong> Bei aktivem Sync werden ggf. vorgenommene Änderungen an Angebotsdaten auf GetYourDrive überschrieben und gehen verloren! <br><br>Synchronisation aktivieren, sind Sie sicher?'
          );
        });
      }
    }


    if (removeCarFromSyncBtns && removeCarFromSyncBtns.length > 0) {
      for (let removeFromSnycBtn of removeCarFromSyncBtns) {
        removeFromSnycBtn.addEventListener('click', function (e) {
          syncToggleClickHandler( e, 'Fahrzeug Import #' + this.dataset.id + ' von Synchronisation ausnehmen' );
        });
      }
    }
    if (removeOfferFromSyncBtns && removeOfferFromSyncBtns.length > 0) {
      for (let removeFromSnycBtn of removeOfferFromSyncBtns) {
        removeFromSnycBtn.addEventListener('click', function (e) {
          syncToggleClickHandler( e, 'Angebots Import #' + this.dataset.id + ' von Synchronisation ausnehmen' );
        });
      }
    }

    function syncToggleClickHandler( e, title, customQuestion = null ) {
      let btn = e.target;
      if (btn.classList.contains('fas')) {
        btn = btn.parentNode;
      }

      ModalControl.show(
        'modal-simple-confirm',
        {
          title: title,
          question: (customQuestion !== null && customQuestion !== '') ? customQuestion : 'Sind Sie sicher?',
          buttons: [
            {
              classes: 'btn btn-secondary',
              action: function(close){ close(); },
              text: 'Abbrechen'
            },
            {
              classes: 'btn btn-primary',
              action: function( close, element ){
                let request = $.get(btn.dataset.url, {id: btn.dataset.id});
                request.done((result) => {
                  if (result.state === 'failed') {
                    console.error('ERROR MESSAGE: ', result.msg);
                    element.showAlertMessage(result.msg );
                  }
                  else {
                    window.location.reload(true);
                  }
                });
                close();
              },
              text: 'Bestätigen'
            }
          ]
        }
      );
    }
  }
  handleMobileCsvImportActions();


  /**
   * Handle newsletter subscription.
   */
  function handleNewsletterSubscription() {
    let newsletterSubscriptionBtns = document.getElementsByClassName('nl-subscription'),
        firstNameFields = document.getElementsByClassName('nl-first-name'),
        firstNameErrorCont = document.getElementsByClassName('nl-first-name-error'),
        lastNameFields = document.getElementsByClassName('nl-last-name'),
        lastNameErrorCont = document.getElementsByClassName('nl-last-name-error'),
        emailFields = document.getElementsByClassName('nl-email'),
        emailErrorCont = document.getElementsByClassName('nl-email-error'),
        privacyAcceptCBs = document.getElementsByClassName('nl-privacy-terms-accepted'),
        privacyAcceptErrorCont = document.getElementsByClassName('nl-privacy-terms-accepted-error');

    if ( !newsletterSubscriptionBtns || newsletterSubscriptionBtns.length <= 0 ) {
      return;
    }

    for ( let i=0; i < newsletterSubscriptionBtns.length; i++ ) {
      newsletterSubscriptionBtns[i].addEventListener( 'click', function(e){
        let btn = e.target,
            firstName = firstNameFields[i],
            firstNameError = firstNameErrorCont[i],
            lastName = lastNameFields[i],
            lastNameError = lastNameErrorCont[i],
            email = emailFields[i],
            emailError = emailErrorCont[i],
            privacyAccept = privacyAcceptCBs[i],
            privacyAcceptError = privacyAcceptErrorCont[i];

        let request = $.post(btn.dataset.url, {
          first_name: firstName.value,
          last_name: lastName.value,
          email: email.value,
          privacy_terms_accepted: privacyAccept.classList.contains('active')
        });

        request.done((result) => {
          cleanNLFieldsAll();
          if (result.state === 'failed') {
            if ( result.errors ) {

              Object.keys( result.errors ).forEach( function( field ){
                switch ( field ) {
                  case 'first_name':
                    firstName.classList.add('is-invalid');

                    result.errors[field].forEach(function(error){
                      firstNameError.appendChild( createNLErrorNode( error ) );
                    });
                    break;
                  case 'last_name':
                    lastName.classList.add('is-invalid');
                    result.errors[field].forEach(function(error){
                      lastNameError.appendChild( createNLErrorNode( error ) );
                    });
                    break;
                  case 'email':
                    email.classList.add('is-invalid');
                    result.errors[field].forEach(function(error){
                      emailError.appendChild( createNLErrorNode( error ) );
                    });
                    break;
                  case 'privacy_terms_accepted':
                    privacyAccept.classList.add('border', 'border-danger');
                    result.errors[field].forEach(function(error){
                      privacyAcceptError.appendChild( createNLErrorNode( error ) );
                    });
                    break;
                }
              });
            }
            else {
              ModalControl.show(
                'modal-message',
                {
                  message: result.msg
                }
              );
            }
          } else {

            cleanNLField( i, false );

            ModalControl.show(
              'modal-message',
              {
                message: result.msg
              }
            );
          }
        });

        request.fail((jqXHR, textStatus, errorThrown) => {
          console.error( 'E:', textStatus );
          console.error( jqXHR );
          console.error( errorThrown );

          ModalControl.show(
            'modal-message',
            {
              message: 'Ein unerwarteter Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal.'
            }
          );
        });
      });

    }

    function createNLErrorNode( error ) {
      let errorContainer = document.createElement("div");
      let errorElement = document.createElement("small");
      let errorContent = document.createTextNode(error);
      errorElement.appendChild( errorContent );
      errorContainer.appendChild( errorElement );
      return errorContainer;
    }

    function cleanNLFieldsAll() {
      let i = 0,
          length = firstNameFields.length;

      for (i; i<length; i++) {
        cleanNLField(i, true);
      }
    }

    function cleanNLField( i, errors_only ) {

      if ( !errors_only ) {
        firstNameFields[i].value = '';
        lastNameFields[i].value = '';
        emailFields[i].value = '';
        privacyAcceptCBs[i].classList.remove('active');
      }

      firstNameFields[i].classList.remove('is-invalid');
      while (firstNameErrorCont[i].firstChild) {
        firstNameErrorCont[i].removeChild(firstNameErrorCont[i].firstChild);
      }

      lastNameFields[i].classList.remove('is-invalid');
      while (lastNameErrorCont[i].firstChild) {
        lastNameErrorCont[i].removeChild(lastNameErrorCont[i].firstChild);
      }

      emailFields[i].classList.remove('is-invalid');
      while (emailErrorCont[i].firstChild) {
        emailErrorCont[i].removeChild(emailErrorCont[i].firstChild);
      }

      privacyAcceptCBs[i].classList.remove('border', 'border-danger');
      while (privacyAcceptErrorCont[i].firstChild) {
        privacyAcceptErrorCont[i].removeChild(privacyAcceptErrorCont[i].firstChild);
      }
    }
  }
  handleNewsletterSubscription();


  /**
   * Handle privacy terms accept checkbox on users/sign_up.
   */
  function handlePrivacyTermsAcception(){
    let privacyTermsAcceptCB = document.getElementById('privacy_terms_accepted'),
        privacyTermsAcceptInput = document.getElementById('user_privacy_terms_accepted');

    if ( !privacyTermsAcceptCB || typeof(privacyTermsAcceptCB) === 'undefined' ) {
      return;
    }

    privacyTermsAcceptCB.addEventListener( 'click', function(e){
      if ( e.target.nodeName !== 'INPUT' ) {
        return;
      }
      privacyTermsAcceptInput.value = privacyTermsAcceptInput.value === '0' ? '1' : '0';
    });
  }
  handlePrivacyTermsAcception();

  /**
   * Handle MAIN Navbar highlighting.
   */
  function handleNavbarHighlighting(){
    let navbarUl = document.getElementById('mainNavbar'),
        navbarLiChilds = navbarUl.getElementsByTagName('li');

    navbarLiChilds.forEach((child)=>{
      let href = child.firstChild.attributes.getNamedItem('href');
      if ( href && href.value === (window.location.pathname + window.location.search))
      {
        child.classList.add('active')
      }
      else {
        child.classList.remove('active')
      }
    });

  }
  handleNavbarHighlighting();

});
